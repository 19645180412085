import { gql } from "@apollo/client";
import { CategoryId, HexId, OmitRecursively, asciiCompare } from "@hex/common";
import {
  Action,
  EntityState,
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { useCallback } from "react";
import { createSelector } from "reselect";

import { useDispatch, useSelector } from "../hooks.js";
import { RootState } from "../store.js";

import { HexSelectionFragment } from "./hexSelectionSlice.generated.js";

gql`
  fragment HexSelectionFragment on Hex {
    id
    canDelete
    canEditMetadata
    canSetOwner
    canUseApp
    status {
      id
    }
    categories {
      id
    }
  }
`;

type HexSelectionPerm =
  | "canDelete"
  | "canEditMetadata"
  | "canSetOwner"
  | "canUseApp";

type HexSelectionData = OmitRecursively<HexSelectionFragment, "__typename">;
const hexSelectionDataAdapter = createEntityAdapter<HexSelectionData>({
  selectId: (ele) => ele.id,
  sortComparer: (a, b) => asciiCompare(a.id, b.id),
});

export type HexSelectionSliceState = {
  selectedHexes: EntityState<HexSelectionData>;
  // AG grid currently does not support controlled selection state
  // @see https://ag-grid.zendesk.com/hc/en-us/requests/32181
  // so we do this 'hack' to avoid ag grid from accidentally causing a circular update
  // and inverting the selection state on range selection
  lastUpdater: "redux" | "ag";
  lastUpdatedHexId: HexId | null;
};

const initialState: HexSelectionSliceState = {
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization -- no side effects
  selectedHexes: hexSelectionDataAdapter.getInitialState(),
  lastUpdater: "redux",
  lastUpdatedHexId: null,
};

export const MAX_HEX_SELECTION_SIZE = 250;

const hexSelectionSlice = createSlice({
  name: "me",
  initialState,
  reducers: {
    select: (
      state,
      action: PayloadAction<{
        hex: HexSelectionData;
        lastUpdater?: "redux" | "ag";
      }>,
    ) => {
      const prev = state.selectedHexes.entities[action.payload.hex.id];
      if (
        prev == null &&
        state.selectedHexes.ids.length < MAX_HEX_SELECTION_SIZE
      ) {
        hexSelectionDataAdapter.addOne(state.selectedHexes, action.payload.hex);
        state.lastUpdatedHexId = action.payload.hex.id;
        state.lastUpdater = action.payload.lastUpdater ?? "redux";
      }
    },
    updateData: (
      state,
      action: PayloadAction<{
        hexes: ReadonlyArray<
          Partial<HexSelectionData> & Pick<HexSelectionData, "id">
        >;
      }>,
    ) => {
      hexSelectionDataAdapter.updateMany(
        state.selectedHexes,
        action.payload.hexes.map((hex) => ({
          id: hex.id,
          changes: hex,
        })),
      );
    },
    deselect: (
      state,
      action: PayloadAction<{ hexId: HexId; lastUpdater?: "redux" | "ag" }>,
    ) => {
      const prev = state.selectedHexes.entities[action.payload.hexId];
      if (prev != null) {
        hexSelectionDataAdapter.removeOne(
          state.selectedHexes,
          action.payload.hexId,
        );
        state.lastUpdatedHexId = action.payload.hexId;
        state.lastUpdater = action.payload.lastUpdater ?? "redux";
      }
    },
    deselectByPerm: (
      state,
      action: PayloadAction<{ perm: HexSelectionPerm }>,
    ) => {
      state.lastUpdatedHexId = null;
      for (const hex of Object.values(state.selectedHexes.entities)) {
        if (hex != null && hex[action.payload.perm] === false) {
          hexSelectionDataAdapter.removeOne(state.selectedHexes, hex.id);
          state.lastUpdater = "redux";
        }
      }
    },
    clear: (state, _action: Action) => {
      state.lastUpdatedHexId = null;
      if (state.selectedHexes.ids.length !== 0) {
        hexSelectionDataAdapter.removeAll(state.selectedHexes);
        state.lastUpdater = "redux";
      }
    },
  },
});

export const hexSelectionReducer = hexSelectionSlice.reducer;
export const hexSelectionActions = hexSelectionSlice.actions;

const selectState = (state: RootState): HexSelectionSliceState =>
  state.hexSelection;

// eslint-disable-next-line tree-shaking/no-side-effects-in-initialization -- no side effects
const hexSelectionDataSelectors = hexSelectionDataAdapter.getSelectors(
  (state: RootState) => selectState(state).selectedHexes,
);

const selectSelectedIds = hexSelectionDataSelectors.selectIds as (
  state: RootState,
) => HexId[];

const selectSelectedCount = hexSelectionDataSelectors.selectTotal;

const selectAnySelected = (state: RootState): boolean =>
  selectSelectedCount(state) > 0;

const selectSelectedIdsSet = createSelector(
  selectSelectedIds,
  (hexIds) => new Set(hexIds),
);

const selectSelectedStatusIdsSet = createSelector(
  hexSelectionDataSelectors.selectAll,
  (hexes) => new Set(hexes.map((hex) => hex.status?.id)),
);

const selectSelectedCountsByCategoryId = createSelector(
  hexSelectionDataSelectors.selectAll,
  (hexes) =>
    hexes.reduce<Record<CategoryId, number>>((acc, hex) => {
      for (const category of hex.categories) {
        acc[category.id] = (acc[category.id] ?? 0) + 1;
      }
      return acc;
    }, {}),
);

const selectIsHexSelected = (state: RootState, hexId: HexId): boolean =>
  hexSelectionDataSelectors.selectById(state, hexId) != null;

const selectLastUpdater = (state: RootState): "redux" | "ag" =>
  selectState(state).lastUpdater;

const selectLastUpdatedHexId = (state: RootState): HexId | null =>
  selectState(state).lastUpdatedHexId;

const countPermSelector = (
  perm: HexSelectionPerm,
): ((state: RootState) => number) =>
  createSelector(
    hexSelectionDataSelectors.selectAll,
    (hexes) => hexes.filter((hex) => hex[perm] === true).length,
  );

const countPerm: Record<HexSelectionPerm, (state: RootState) => number> = {
  canDelete: countPermSelector("canDelete"),
  canEditMetadata: countPermSelector("canEditMetadata"),
  canSetOwner: countPermSelector("canSetOwner"),
  canUseApp: countPermSelector("canUseApp"),
};

const createPermSelector = (
  perm: HexSelectionPerm,
): ((state: RootState) => boolean) =>
  createSelector(hexSelectionDataSelectors.selectAll, (hexes) =>
    hexes.every((hex) => hex[perm] === true),
  );

const selectPerm: Record<HexSelectionPerm, (state: RootState) => boolean> = {
  canDelete: createPermSelector("canDelete"),
  canEditMetadata: createPermSelector("canEditMetadata"),
  canSetOwner: createPermSelector("canSetOwner"),
  canUseApp: createPermSelector("canUseApp"),
};

export const hexSelectionSelectors = {
  selectSelectedIds,
  selectSelectedCount,
  selectAnySelected,
  selectSelectedIdsSet,
  selectSelectedStatusIdsSet,
  selectSelectedCountsByCategoryId,
  selectIsHexSelected,
  selectLastUpdater,
  selectLastUpdatedHexId,
  countPerm,
  selectPerm,
};

export interface SelectionPerm {
  countWithPerm: number;
  hasPerm: boolean;
  deselectByPerm: () => void;
}

export function useHexSelectionPerm(perm: HexSelectionPerm): SelectionPerm {
  const countWithPerm = useSelector(hexSelectionSelectors.countPerm[perm]);
  const hasPerm = useSelector(hexSelectionSelectors.selectPerm[perm]);

  const dispatch = useDispatch();
  const deselectByPerm = useCallback(() => {
    dispatch(hexSelectionActions.deselectByPerm({ perm }));
  }, [dispatch, perm]);

  return {
    countWithPerm,
    hasPerm,
    deselectByPerm,
  };
}

import {
  HexScheduleId,
  SavedViewId,
  ScheduleNotificationId,
  StaticCellId,
} from "@hex/common";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../store";

interface ScheduleNotificationModalState {
  isOpen: boolean;
  scheduleNotificationId: ScheduleNotificationId | null;
  staticCellId: StaticCellId | null;
  scheduleId: HexScheduleId | null;
}

interface EditScheduleModalState {
  isOpen: boolean;
  scheduleId: HexScheduleId | null;
}

type AppViewSliceState = {
  scheduleNotificationModal: ScheduleNotificationModalState;
  editScheduleModal: EditScheduleModalState;
  hasSchedules: boolean;
  savedViewId: SavedViewId | null;
};

const initialAppViewState: AppViewSliceState = {
  scheduleNotificationModal: {
    isOpen: false,
    staticCellId: null,
    scheduleNotificationId: null,
    scheduleId: null,
  },
  editScheduleModal: {
    isOpen: false,
    scheduleId: null,
  },
  hasSchedules: false,
  savedViewId: null,
};

const appViewSlice = createSlice({
  name: "appView",
  initialState: initialAppViewState,
  reducers: {
    // This modal state is intended for more general use and doesn't assume
    // a starting scheduleId. If a staticCellId is provided, it will be used
    // to start a conditional notification (otherwise, it will start a subscription).
    openScheduleNotificationModalNewNotification: (
      state,
      action: PayloadAction<StaticCellId | undefined>,
    ) => {
      state.scheduleNotificationModal.isOpen = true;
      state.scheduleNotificationModal.scheduleId = null;
      state.scheduleNotificationModal.staticCellId = action.payload ?? null;
    },
    // This modal state requires a staticCellId and is intended for use in
    // cell-native UIs
    openScheduleNotificationModalForCell: (
      state,
      action: PayloadAction<StaticCellId>,
    ) => {
      state.scheduleNotificationModal.isOpen = true;
      state.scheduleNotificationModal.staticCellId = action.payload;
    },
    // This modal state requires a scheduleId and is intended for use in
    // schedule-native UIs
    openScheduleNotificationModalForSchedule: (
      state,
      action: PayloadAction<{
        scheduleId: HexScheduleId;
        staticCellId?: StaticCellId;
      }>,
    ) => {
      state.scheduleNotificationModal.isOpen = true;
      state.scheduleNotificationModal.scheduleId = action.payload.scheduleId;
      state.scheduleNotificationModal.staticCellId =
        action.payload.staticCellId ?? null;
    },
    // This modal state assumes an existing notification coupled with an existing schedule
    openScheduleNotificationModalForNotification: (
      state,
      action: PayloadAction<{
        scheduleId: HexScheduleId;
        scheduleNotificationId: ScheduleNotificationId;
      }>,
    ) => {
      state.scheduleNotificationModal.isOpen = true;
      state.scheduleNotificationModal.scheduleId = action.payload.scheduleId;
      state.scheduleNotificationModal.scheduleNotificationId =
        action.payload.scheduleNotificationId;
    },
    closeScheduleNotificationModal(state) {
      state.scheduleNotificationModal.isOpen = false;
      state.scheduleNotificationModal.staticCellId = null;
      state.scheduleNotificationModal.scheduleId = null;
      state.scheduleNotificationModal.scheduleNotificationId = null;
    },
    openEditScheduleModal: (state, action: PayloadAction<HexScheduleId>) => {
      state.editScheduleModal.isOpen = true;
      state.editScheduleModal.scheduleId = action.payload;
    },
    closeEditScheduleModal: (state) => {
      state.editScheduleModal.isOpen = false;
      state.editScheduleModal.scheduleId = null;
    },
    setHasSchedules: (state, action: PayloadAction<boolean>) => {
      state.hasSchedules = action.payload;
    },
    setSavedViewId: (state, action: PayloadAction<SavedViewId | null>) => {
      state.savedViewId = action.payload;
    },
  },
});

export const selectCurrentSavedViewId = (
  state: RootState,
): SavedViewId | null => state.appView.savedViewId;

export const selectNotificationModalState = (
  state: RootState,
): ScheduleNotificationModalState => state.appView.scheduleNotificationModal;

export const selectEditScheduleModalState = (
  state: RootState,
): EditScheduleModalState => state.appView.editScheduleModal;

export const selectHasSchedules = (state: RootState): boolean =>
  state.appView.hasSchedules;

export const appViewReducer = appViewSlice.reducer;
export const {
  closeEditScheduleModal,
  closeScheduleNotificationModal,
  openEditScheduleModal,
  openScheduleNotificationModalForCell,
  openScheduleNotificationModalForNotification,
  openScheduleNotificationModalForSchedule,
  openScheduleNotificationModalNewNotification,
  setHasSchedules,
  setSavedViewId,
} = appViewSlice.actions;

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { BrowserStorageType } from "../../util/browserStorage.js";

type SessionStorageSliceState = {
  [storageType in BrowserStorageType]: {
    [key: string]: string | undefined;
  };
};

const initialState: SessionStorageSliceState = {
  Local: {},
  Session: {},
};

const browserStorageSlice = createSlice({
  name: "browserStorage",
  initialState,
  reducers: {
    setValue: (
      state,
      action: PayloadAction<{
        storageType: BrowserStorageType;
        key: string;
        value: string;
      }>,
    ) => {
      const {
        payload: { key, storageType, value },
      } = action;
      state[storageType][key] = value;
    },
  },
});

export const browserStorageReducer = browserStorageSlice.reducer;
export const browserStorageActions = browserStorageSlice.actions;

import { BlockCellId, CellId, ComponentImportCellId } from "@hex/common";

/**
 * Location that is relative to an existing cell.
 *
 * When dealing with different depths, this resolves to the location closest
 * to the target cell at the same depth.
 *
 * If not trying to insert as a child, you should use {@link ChildCellLocation} instead
 *
 * @example
 * For the following list of cells:
 * ```
 * A
 *   B
 *   C
 * D
 * ```
 *
 * inserting cell `X` at:
 * ```
 * {
 *   type: "relative",
 *   targetCellId: "A",
 *   position: "after",
 * }
 * ```
 *
 * will result in:
 * ```
 * A
 *   B
 *   C
 * X
 * D
 * ```
 *
 * while inserting cell `X` at:
 * ```
 * {
 *   type: "relative",
 *   targetCellId: "C",
 *   position: "after",
 * }
 * ```
 *
 * will result in:
 * ```
 * A
 *   B
 *   C
 *   X
 * D
 * ```
 */
export type RelativeCellLocation = {
  type: "relative";
  targetCellId: CellId;
  position: "before" | "after";
};

/**
 * Location that is the child of a given parent.
 *
 * If no parent is provided, it's a child of the top-level scope.
 *
 * If not trying to insert at the first or last position as a child,
 * you should use {@link RelativeCellLocation} instead
 */
export type ChildCellLocation = {
  type: "child";
  parentCellId: CellId | null;
  position: "first" | "last";
};

/**
 * Special location for use when inserting to an 'unknown' location.
 * Resolves to just after the first currently selected cell or the final cell
 * of the top-level scope.
 *
 * In general, prefer {@link RelativeCellLocation} or {@link ChildCellLocation}
 * if trying to insert to a known location.
 *
 * @example
 * If no cells are selected, this resolves to:
 * ```
 * {
 *   type: "child",
 *   parentCellId: null,
 *   position: "last",
 * }
 * ```
 *
 * If cells are selected, this resolves to:
 * ```
 * {
 *   type: "relative",
 *   targetCellId: "id of the first selected cell",
 *   position: "after",
 * }
 * ```
 */
export type GlobalCellLocation = {
  type: "global";
};

/**
 * Singleton to make global inserts easier with respect
 * to referential equality
 */
export const GLOBAL_CELL_LOCATION: GlobalCellLocation = {
  type: "global",
};

/**
 * Descriptor of where a cell can be inserted or moved to.
 *
 * Used both for the actual insertion/moving of cells and
 * for components such as the magic prompt bar so it understands
 * where to render itself.
 */
export type CellLocation =
  | RelativeCellLocation
  | ChildCellLocation
  | GlobalCellLocation;

/**
 * Given a declarative {@link CellLocation}, the resolved data
 * needed to actually insert or move it.
 *
 * {@link CellLocation} is a very useful way to declaratively say
 * where a cell should be inserted, but lacks the concrete data to do so.
 *
 * This type is best used 'just in time' of the insert/move,
 * while {@link CellLocation} is better used everywhere else.
 */
export type ResolvedCellLocation = {
  order: string;
  parentCellId: CellId | null;

  // while `order` + `parentCellId` should be good enough
  // to absolutely describe a cell location, we still need
  // `parentBlockCellId` + `parentComponentImportCellId` to
  // perform an insert since these are required cell fields
  parentBlockCellId: BlockCellId | null;
  parentComponentImportCellId: ComponentImportCellId | null;
};

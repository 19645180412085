import { OmitRecursively } from "@hex/common";
import { Action, PayloadAction, createSlice } from "@reduxjs/toolkit";

import { MeQuery } from "../../route/HexRoutes.generated";
import { RootState } from "../store";

export type CurrentUser = OmitRecursively<
  NonNullable<MeQuery["me"]>,
  "__typename"
>;

export type CurrentUserState = CurrentUser | null;

const currentUserSlice = createSlice({
  name: "currentUser",
  initialState: null as CurrentUserState,
  reducers: {
    set: (_, action: PayloadAction<CurrentUserState>) => action.payload,
    clear: (_, __: Action) => null,
  },
});

export const currentUserActions = {
  ...currentUserSlice.actions,
} as const;

export const currentUserReducer = currentUserSlice.reducer;
export const currentUserSelectors = {
  select: (state: RootState): CurrentUserState => state.currentUser,
};
